import toast from 'react-hot-toast';
import axios from '../modules/axios';
import ResponseData from '../types/AxiosResponse';

const getAllUsers = async () => {
  try {
    const res = await axios.get<never, ResponseData>('/users/get/all');
    sessionStorage.setItem('allUsers', JSON.stringify(res.data.users));
    return res.data.users;
  } catch (err: any) {
    toast.error(err.message, { id: 'api error' });
    console.log(err);
  }
};

export default getAllUsers;
