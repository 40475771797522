import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import socket from '../../../modules/socket';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setUser } from '../../../redux/reducer';
import ChannelList from '../../ChannelList';
import Details from '../../Details';
import MessageWindow from '../../MessageWindow';
import ServerList from '../../ServerList';
import Loader from '../../shared/Loader';
import Auth from '../Auth';
import './style.scss';

interface Props {
  side: 'left' | 'right';
}

const SideBar = ({ side }: Props) => {
  if (side === 'left') {
    return (
      <div className="side-bar">
        <ServerList />
        <ChannelList />
      </div>
    );
  } else if (side === 'right') {
    return <Details />;
  }
};

const Home = () => {
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const User = useAppSelector((state) => state.user);
  let token = localStorage.getItem('token') || (User && User.token) || undefined;
  useEffect(() => {
    token = localStorage.getItem('token') || (User && User.token) || undefined;
    if (token) {
      setLoading(true);
      socket.connect();
      socket.on('connect', () => {
        socket
          .emit('authenticate', { token })
          .on('authenticated', (user) => {
            dispatch(setUser(JSON.parse(JSON.stringify(user))));
            setAuth(true);
            setLoading(false);
          })
          .on('unAuthorized', (message) => {
            setAuth(false);
            dispatch(setUser(null));
            localStorage.clear();
            sessionStorage.clear();
            toast.error(message, { id: 'unauthorized' });
            setLoading(false);
            window.location.reload();
          });
      });
    }
  }, [token]);
  return (
    <>
      {loading && <Loader />}
      {auth ? (
        <div className="home">
          <SideBar side="left" />
          <MessageWindow />
          <Details />
        </div>
      ) : (
        <Auth />
      )}
    </>
  );
};

export default Home;
