import { MessageType, UserType } from '../redux/reducer';

export const convertMutable = (allMessages: MessageType[], me: UserType) => {
  const mutatedMessages: MessageType[] = [];
  if (!allMessages) return allMessages;
  if (Array.isArray(allMessages))
    allMessages.forEach((message) => {
      if (message) {
        const { id, sender, time, messages, type, status } = message;
        const { condition = sender.id == me.id ? 'send' : 'receive' } = message;
        if (type === 'file') {
          const file = messages[0].split(',');
          mutatedMessages.push({ id, sender: { ...sender }, time, messages: [...file], condition, type, status });
        } else
          mutatedMessages.push({ id, sender: { ...sender }, time, messages: [...messages], condition, type, status });
      }
    });
  else
    [allMessages].forEach((message: MessageType) => {
      if (message) {
        const { id, sender, time, messages, type, status } = message;
        const { condition = sender.id == me.id ? 'send' : 'receive' } = message;
        if (type === 'file') {
          const file = messages[0].split(',');
          mutatedMessages.push({ id, sender: { ...sender }, time, messages: [...file], condition, type, status });
        } else
          mutatedMessages.push({ id, sender: { ...sender }, time, messages: [...messages], condition, type, status });
      }
    });
  return mutatedMessages;
};

export const groupMessages = (allMessages: MessageType[]) => {
  const createGroups = (messages: MessageType[], index = 0) => {
    if (messages && messages[index + 1]) {
      if (messages[index].type == 'message' && messages[index + 1].type == 'message') {
        let time = new Date(messages[index].time).toLocaleString('en-US', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
        let time1 = new Date(messages[index + 1].time).toLocaleString('en-US', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
        if (messages[index].sender.id == messages[index + 1].sender.id && time == time1) {
          const nextMessages = messages[index + 1].messages as string[];
          messages[index].messages = messages[index].messages.concat(nextMessages);
          messages.splice(index + 1, 1);
          messages = createGroups(messages, index);
        } else {
          messages = createGroups(messages, index + 1);
        }
      } else {
        messages = createGroups(messages, index + 1);
      }
    }
    return messages;
  };
  return createGroups(allMessages);
};
