import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';
import { EntryComponentProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry';
import DOMPurify from 'dompurify';
import { convertToHTML } from 'draft-convert';
import { ContentState, EditorState, Modifier, RichUtils } from 'draft-js';
import createMarkdownShortcutsPlugin from 'draft-js-markdown-shortcuts-plugin';
import { BaseEmoji, Picker } from 'emoji-mart';
import { OrderedSet } from 'immutable';
import React, { ComponentType, useMemo, useRef, useState } from 'react';
import { AiOutlineOrderedList, AiOutlineUnorderedList } from 'react-icons/ai';
import { BiBold, BiCodeAlt, BiStrikethrough, BiUnderline } from 'react-icons/bi';
import { BsBlockquoteLeft, BsPlus } from 'react-icons/bs';
import { GrEmoji, GrItalic } from 'react-icons/gr';
import { RiSendPlaneFill } from 'react-icons/ri';
import '../../../../node_modules/draft-js/dist/Draft.css';
import socket from '../../../modules/socket';
import { useAppSelector } from '../../../redux/hooks';
import { ChannelType, UserType } from '../../../redux/reducer';
import Avatar from '../Avatar';
import './style.scss';

interface Props {
  sendMessage: Function;
  getFiles: Function;
  placeholder: string;
}

const EntryComponentUser: ComponentType<EntryComponentProps> = ({
  mention,
  theme,
  searchValue,
  isFocused,
  ...parentProps
}) => {
  return (
    <div {...parentProps}>
      <div className={theme.mentionSuggestionsEntryAvatar}>
        <Avatar user={mention as UserType} />
      </div>
      <div className={theme.mentionSuggestionsEntryText}>{mention.name}</div>
    </div>
  );
};
const EntryComponentChannel: ComponentType<EntryComponentProps> = ({
  mention,
  theme,
  searchValue,
  isFocused,
  ...parentProps
}) => {
  return (
    <div {...parentProps}>
      <div className={theme.mentionSuggestionsEntryText}>{mention.name}</div>
    </div>
  );
};

const RichInput = ({ sendMessage, getFiles, placeholder }: Props) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [selectEmoji, setSelectEmoji] = useState(false);
  const editor = useRef<Editor>(null);
  const [open, setOpen] = useState(false);
  const me = useAppSelector((state) => state.user);
  const activeServer = useAppSelector((state) => state.activeServer);
  const activeChannel = useAppSelector((state) => state.activeChannel);
  const activeUser = useAppSelector((state) => state.activeUser);
  const allUsers = useAppSelector((state) => state.allUsers.filter((user: UserType) => user.id !== me.id)) || [];
  const serverChannels = useAppSelector((state) => state.serverChannels[activeServer && activeServer.id]) || [];
  const [suggestionsUsers, setSuggestionsUsers] = useState(allUsers);
  const [suggestionsChannels, setSuggestionsChannels] = useState(serverChannels);
  const userMentions: UserType[] = [];
  let sendStatus = true;

  const { MentionSuggestionsUser, MentionSuggestionsChannel, Plugins } = useMemo(() => {
    const mentionPluginUser = createMentionPlugin({
      supportWhitespace: true,
      mentionPrefix: '@',
      mentionTrigger: '@',
      theme: {
        mention: 'mention',
        mentionSuggestions: 'suggestions',
        mentionSuggestionsPopup: 'suggestion-popup',
        mentionSuggestionsPopupVisible: 'suggestion-popup-visible',
        mentionSuggestionsEntry: 'suggestion-entry',
        mentionSuggestionsEntryFocused: 'suggestion-entry-focused',
        mentionSuggestionsEntryText: 'suggestion-entry-text',
        mentionSuggestionsEntryAvatar: 'suggestion-entry-avatar',
      },
    });
    const mentionPluginChannel = createMentionPlugin({
      supportWhitespace: true,
      mentionPrefix: '#',
      mentionTrigger: '#',
      theme: {
        mention: 'mention',
        mentionSuggestions: 'suggestions',
        mentionSuggestionsPopup: 'suggestion-popup',
        mentionSuggestionsPopupVisible: 'suggestion-popup-visible',
        mentionSuggestionsEntry: 'suggestion-entry',
        mentionSuggestionsEntryFocused: 'suggestion-entry-focused',
        mentionSuggestionsEntryText: 'suggestion-entry-text',
      },
    });
    const plugins = [createMarkdownShortcutsPlugin(), mentionPluginUser, mentionPluginChannel];
    return {
      Plugins: plugins,
      MentionSuggestionsUser: mentionPluginUser.MentionSuggestions,
      MentionSuggestionsChannel: mentionPluginChannel.MentionSuggestions,
    };
  }, []);

  const onOpenChange = (_open: boolean) => {
    setOpen(_open);
  };

  const onSearchChangeUser = ({ value }: { value: string }) => {
    setSuggestionsUsers(defaultSuggestionsFilter(value, allUsers) as UserType[]);
  };

  const onSearchChangeChannels = ({ value }: { value: string }) => {
    setSuggestionsChannels(defaultSuggestionsFilter(value, serverChannels) as ChannelType[]);
  };

  const selectEmojiHandler = (emoji: BaseEmoji) => {
    const newEditorState = Modifier.insertText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      emoji.native
    );
    setEditorState(EditorState.push(editorState, newEditorState, 'insert-characters'));
    setSelectEmoji(false);
  };

  const icon = {
    width: '1.9rem',
    height: '1.9rem',
    minWidth: '1.9rem',
    padding: '5px',
  };

  const toolbarButton = [
    {
      title: 'Bold',
      style: 'BOLD',
      type: 'inline',
      icon: <BiBold style={icon} />,
    },
    {
      title: 'Italic',
      style: 'ITALIC',
      type: 'inline',
      icon: <GrItalic style={icon} />,
    },
    {
      title: 'Underline',
      style: 'UNDERLINE',
      type: 'inline',
      icon: <BiUnderline style={icon} />,
    },
    {
      title: 'Strikethrough',
      style: 'STRIKETHROUGH',
      type: 'inline',
      icon: <BiStrikethrough style={icon} />,
    },
    {
      title: 'Code',
      style: 'CODE',
      type: 'inline',
      icon: <BiCodeAlt style={icon} />,
    },
    {
      title: 'Ordered list',
      style: 'ordered-list-item',
      type: 'block',
      icon: <AiOutlineOrderedList style={icon} />,
    },
    {
      title: 'Unordered list',
      style: 'unordered-list-item',
      type: 'block',
      icon: <AiOutlineUnorderedList style={icon} />,
    },
    {
      title: 'Blockquote',
      style: 'blockquote',
      type: 'block',
      icon: <BsBlockquoteLeft style={icon} />,
    },
  ];

  const sendUserMentions = () => {
    if (activeChannel) {
      socket.emit('sendMentionNotification', {
        mentions: userMentions.map((mention) => ({ id: mention.id, name: mention.name })),
        sender: me.name,
        channel: { id: activeChannel.id, name: activeChannel.name, server: activeChannel.server },
      });
    }
  };

  const handleChange = (newEditorState: EditorState) => {
    if (newEditorState.getCurrentContent().getPlainText().length > 3000) {
      console.log('entered');
      setEditorState(EditorState.createEmpty());
    } else {
      setEditorState(newEditorState);
      if (newEditorState.getCurrentContent().getPlainText().trim().length > 0) {
        if (sendStatus) {
          let type = activeChannel ? 'group' : 'private';
          socket.emit('userTyping', {
            room: (activeChannel && activeChannel.id) || (activeUser && activeUser.id),
            userName: me.name,
            type,
            typingUserId: me.id,
          });
          sendStatus = false;
          setTimeout(() => {
            sendStatus = true;
          }, 500);
        }
      } else {
        let type = activeChannel ? 'group' : 'private';
        socket.emit('userStoppedTyping', {
          room: (activeChannel && activeChannel.id) || (activeUser && activeUser.id),
          type,
          typingUserId: me.id,
        });
      }
    }
  };

  const focus = () => {
    if (editor.current) {
      editor.current.focus();
    }
  };

  const keyBindings = (event: React.KeyboardEvent<{}>) => {
    if (event.key === 'Enter') {
      return 'send';
    }
    // return getDefaultKeyBinding(event);
  };

  const getMessage = (editorCurrentState: EditorState = editorState) => {
    if (!editorState.getCurrentContent().getPlainText().trim()) {
      const emptyState = EditorState.push(editorCurrentState, ContentState.createFromText(''), 'split-block');
      setEditorState(EditorState.forceSelection(emptyState, emptyState.getSelection()));
      return;
    }
    const html = convertToHTML({
      styleToHTML: (style) => {
        if (style === 'STRIKETHROUGH') {
          return <del />;
        }
      },
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'mention') {
          const mention = entity.data.mention as UserType;
          userMentions.push(mention);
          return (
            <span className="mention" data-id={mention.id}>
              {originalText}
            </span>
          );
        }
        if (entity.type === '#mention') {
          const mention = entity.data.mention as ChannelType;
          return (
            <span className="mention-channel" data-id={mention.id} data-server={mention.server}>
              {originalText}
            </span>
          );
        }
        if (entity.type === 'LINK') {
          return (
            <a href={entity.data.url} target="_blank" rel="noreferrer noopener" className="link">
              {originalText}
            </a>
          );
        }
      },
    })(editorState.getCurrentContent());
    const message = DOMPurify.sanitize(html, {
      ALLOWED_TAGS: ['p', 'span', 'blockquote', 'ol', 'ul', 'li', 'code', 'del', 'strong', 'em', 'u', 'a', 'br'],
      ALLOWED_ATTR: ['target', 'class', 'rel', 'href', 'title'],
      ALLOW_DATA_ATTR: true,
    });
    const emptyState = EditorState.push(editorCurrentState, ContentState.createFromText(''), 'split-block');
    setEditorState(EditorState.forceSelection(emptyState, emptyState.getSelection()));
    if (userMentions.length > 0) {
      sendUserMentions();
      userMentions.length = 0;
    }
    let type = activeChannel ? 'group' : 'private';
    socket.emit('userStoppedTyping', {
      room: (activeChannel && activeChannel.id) || (activeUser && activeUser.id),
      type,
      typingUserId: me.id,
    });
    sendMessage(message);
  };

  const handleKeyCommand = (command: string, editorCurrentState: EditorState) => {
    if (command === 'send') {
      getMessage(editorCurrentState);
      return 'handled';
    }
    const newState = RichUtils.handleKeyCommand(editorCurrentState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const applyStyle = (event: { preventDefault: () => void }, button: typeof toolbarButton[0]) => {
    event.preventDefault();
    if (button.type === 'inline') {
      setEditorState(RichUtils.toggleInlineStyle(editorState, button.style));
    } else {
      setEditorState(RichUtils.toggleBlockType(editorState, button.style));
    }
  };

  const isActive = (style: string) => {
    return editorState.getCurrentInlineStyle().has(style);
  };

  const styleMap: { CODE: React.CSSProperties } = {
    CODE: {
      display: 'inline-block',
      padding: '2px 4px',
      margin: '2px 0',
      borderRadius: '2px',
      fontFamily: '"Fira Mono", monospace',
      overflowWrap: 'break-word',
      background: 'var(--background-light)',
    },
  };
  return (
    <div className="editor">
      <div className="icons">
        <div className="left">
          {toolbarButton.map((button, index) => (
            <button
              key={index}
              className={isActive(button.style) ? 'icon active' : 'icon'}
              onMouseDown={(event) => applyStyle(event, button)}
              title={button.title}
            >
              {button.icon}
            </button>
          ))}
        </div>
        <div className="right">
          <button className="icon" title="Share Files" onClick={() => getFiles()}>
            <BsPlus style={{ ...icon, padding: '2px' }} />
          </button>
          {selectEmoji && (
            <Picker
              title="Accros"
              emoji="the_horns"
              defaultSkin={2}
              theme="dark"
              set="facebook"
              autoFocus
              onSelect={selectEmojiHandler}
              color="var(--primary-color-light)"
              style={{ position: 'absolute', bottom: '4rem', right: '5.2rem' }}
            />
          )}
          <button className="icon" title="Add Emoji" onClick={() => setSelectEmoji((prevValues) => !prevValues)}>
            <GrEmoji className="emoji" style={icon} />
          </button>
          <button className="icon" title="Send Message" onClick={() => getMessage()}>
            <RiSendPlaneFill style={icon} />
          </button>
        </div>
      </div>
      <div className="editor-wrapper" onClick={focus}>
        <Editor
          ref={editor}
          plugins={Plugins}
          placeholder={placeholder}
          editorState={editorState}
          onChange={handleChange}
          customStyleMap={styleMap}
          keyBindingFn={keyBindings}
          handleKeyCommand={handleKeyCommand}
          handlePastedText={(text, html, editorState1) => {
            if (text.length > 3000) {
              setEditorState(EditorState.createEmpty());
              return 'handled';
            } else {
              let nextState = editorState1;
              if (
                text.match(/^((https|http)+:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm)
              ) {
                if (!text.match(/^(https|http)+:\/\//gm)) {
                  text = `http://${text}`;
                }
                const selection = nextState.getSelection();
                if (selection.isCollapsed()) {
                  const contentStateWithEntity = editorState1
                    .getCurrentContent()
                    .createEntity('LINK', 'MUTABLE', { url: text });
                  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                  const contentStateWithLink = Modifier.insertText(
                    contentStateWithEntity,
                    selection,
                    text,
                    OrderedSet.of('BOLD', 'ITALIC', 'UNDERLINE'),
                    entityKey
                  );
                  nextState = EditorState.push(nextState, contentStateWithLink, 'insert-characters');
                  nextState = EditorState.setInlineStyleOverride(nextState, OrderedSet.of(''));
                } else {
                  const content = nextState.getCurrentContent();
                  const contentWithEntity = content.createEntity('LINK', 'MUTABLE', {
                    url: text,
                  });
                  const entityKey = contentWithEntity.getLastCreatedEntityKey();
                  nextState = RichUtils.toggleLink(nextState, selection, entityKey);
                }
                setEditorState(nextState);
                return 'handled';
              }
              return 'not-handled';
            }
          }}
        />
      </div>
      <MentionSuggestionsUser
        open={open}
        suggestions={suggestionsUsers}
        onOpenChange={onOpenChange}
        onSearchChange={onSearchChangeUser}
        entryComponent={EntryComponentUser}
      />
      <MentionSuggestionsChannel
        open={open}
        suggestions={suggestionsChannels}
        onOpenChange={onOpenChange}
        onSearchChange={onSearchChangeChannels}
        entryComponent={EntryComponentChannel}
      />
    </div>
  );
};

export default RichInput;
