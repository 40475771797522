import { useEffect, useRef } from 'react';

const useEventListener = (eventType: keyof WindowEventMap, cb: EventListener, element: any = window) => {
  const callbackRef = useRef(cb);
  useEffect(() => {
    callbackRef.current = cb;
  }, [cb]);
  useEffect(() => {
    if (element == null) return;
    const handler = (event: Event) => callbackRef.current(event);
    element.addEventListener(eventType, handler);
    return () => element.removeEventListener(eventType, handler);
  }, [eventType, element]);
};

export default useEventListener;
