import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import toast from 'react-hot-toast';
import { FcGoogle } from 'react-icons/fc';
import { genConfig } from 'react-nice-avatar';
import axios from '../../../modules/axios';
import { useAppDispatch } from '../../../redux/hooks';
import { setUser } from '../../../redux/reducer';
import Loader from '../../shared/Loader';
import './style.scss';

interface ResponseData {
  error: boolean;
  message: string;
  user: {
    id: string;
    name: string;
    email: string;
    active: boolean;
    status: string;
    avatar: string;
    googleId: string;
    token: string;
    channels?: string[];
  };
}

const Auth = () => {
  const [step, setStep] = useState(0);
  const dispatch = useAppDispatch();
  const AuthForm = {
    name: '',
    sex: '',
    email: '',
    password: '',
    avatarConfig: '',
  };
  const [authForm, setAuthForm] = useState(AuthForm);
  const [disableGoogleButton, setDisableGoogleButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const storeUser = async (url: string, data: typeof AuthForm | { token: string }) => {
    try {
      const res = await axios.post<typeof data, AxiosResponse<ResponseData>>(url, data);
      setLoading(false);
      if (res.data.error === false) {
        toast.success(res.data.message, { id: 'login success' });
        localStorage.setItem('token', res.data.user.token);
        dispatch(setUser(res.data.user));
      } else {
        toast.error(res.data.message, { id: 'login error' });
      }
    } catch (err: any) {
      setLoading(false);
      if (err.response && err.response.status === 401) {
        return toast.error(err.response.message, { id: 'login error' });
      }
      toast.error(err.message, { id: 'api error' });
    }
  };

  const handleInputChange: React.ChangeEventHandler = (event) => {
    const { name, value } = event.target as HTMLInputElement;
    setAuthForm({
      ...authForm,
      [name]: value,
    });
  };

  const registerUser: React.FormEventHandler = async (event) => {
    event.preventDefault();
    storeUser('/users/register', {
      ...authForm,
      avatarConfig: JSON.stringify(genConfig({ hairColorRandom: true, isGradient: true })),
    });
    setAuthForm(AuthForm);
    setLoading(true);
  };

  const loginUser: React.FormEventHandler = async (event) => {
    event.preventDefault();
    storeUser('/users/login', authForm);
    setAuthForm(AuthForm);
    setLoading(true);
  };

  const googleAuthSuccess = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    const data = { token: (response as GoogleLoginResponse).tokenId };
    if (data.token) {
      storeUser('/users/auth/google', data);
      setLoading(true);
    }
  };
  const googleAuthError = (error: any) => {
    console.log(error);
    if (error.error) {
      setLoading(false);
    }
    if (error.error && error.error === 'idpiframe_initialization_failed') {
      setDisableGoogleButton(true);
      toast.error(
        'Google sign in needs third party cookies enabled and you have it disabled. Enable it to sign in with Google.',
        { id: 'google', duration: 5000 }
      );
    }
  };
  return (
    <>
      {loading && <Loader />}
      <div className="auth-container">
        <div className={step === 0 ? 'container' : 'container right-panel-active'} id="container">
          <div className="form-container sign-up-container">
            <form action="" method="post" autoCorrect="off" onSubmit={registerUser}>
              <h1 className="form-heading">Create Account</h1>
              <div className="social-container">
                <GoogleLogin
                  clientId={process.env.GOOGLE_CLIENT_ID}
                  prompt="consent"
                  onSuccess={googleAuthSuccess}
                  onFailure={googleAuthError}
                  cookiePolicy="single_host_origin"
                  render={(renderProps) => (
                    <button
                      className="btn btn-google"
                      onClick={() => {
                        setLoading(true);
                        renderProps.onClick();
                      }}
                      disabled={renderProps.disabled || disableGoogleButton}
                      type="button"
                    >
                      <FcGoogle className="google" />
                      Sign up with Google
                    </button>
                  )}
                />
              </div>
              <span className="separator">or use your email</span>
              <div className="wrapper">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  maxLength={50}
                  autoComplete="name"
                  onChange={handleInputChange}
                  value={authForm.name}
                  required
                />
                <select
                  id="sex"
                  name="sex"
                  title="Select your gender"
                  value={authForm.sex}
                  onChange={handleInputChange}
                  required
                >
                  <option value="man">M</option>
                  <option value="woman">F</option>
                </select>
              </div>
              <input
                type="email"
                name="email"
                id="register-email"
                placeholder="Email"
                autoComplete="email"
                onChange={handleInputChange}
                value={authForm.email}
                pattern="^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                title="Email should be in username@example.com format"
                required
              />
              <input
                type="password"
                name="password"
                id="register-password"
                placeholder="Password"
                minLength={8}
                maxLength={16}
                autoComplete="new-password"
                pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$"
                title="Password must be between 8 to 16 characters, must include atleast one smallcase, one uppercase, one number and one special character."
                onChange={handleInputChange}
                value={authForm.password}
                required
              />
              <button className="btn btn-primary submit" type="submit">
                Sign Up
              </button>
            </form>
          </div>
          <div className="form-container sign-in-container">
            <form action="" method="post" autoCorrect="off" autoComplete="off" onSubmit={loginUser}>
              <h1 className="form-heading">Sign in</h1>
              <div className="social-container">
                <GoogleLogin
                  clientId={process.env.GOOGLE_CLIENT_ID}
                  prompt="consent"
                  onSuccess={googleAuthSuccess}
                  onFailure={googleAuthError}
                  cookiePolicy="single_host_origin"
                  render={(renderProps) => (
                    <button
                      className="btn btn-google"
                      onClick={() => {
                        setLoading(true);
                        renderProps.onClick();
                      }}
                      disabled={renderProps.disabled || disableGoogleButton}
                      type="button"
                    >
                      <FcGoogle className="google" />
                      Sign in with Google
                    </button>
                  )}
                />
              </div>
              <span className="separator">or use your email</span>
              <input
                type="email"
                name="email"
                id="login-email"
                placeholder="Email"
                autoComplete="email"
                onChange={handleInputChange}
                value={authForm.email}
                pattern="^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                title="Email should be in username@example.com format"
                required
              />
              <input
                type="password"
                name="password"
                id="login-password"
                placeholder="Password"
                minLength={8}
                maxLength={16}
                autoComplete="current-password"
                pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$"
                title="Password must be between 8 to 16 characters, must include atleast one smallcase, one uppercase, one number and one special character."
                onChange={handleInputChange}
                value={authForm.password}
                required
              />
              <a className="forgot">Forgot your password?</a>
              <button className="btn btn-primary submit" type="submit">
                Sign In
              </button>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <div className="logo-container">
                  <img src="./accros-logo.png" alt="accros logo" className="logo" />
                </div>
                <h1 className="overlay-heading">Welcome Back..!</h1>
                <p className="overlay-description">To keep you connected please login to your account</p>
                <button className="ghost" id="signIn" onClick={() => setStep(0)}>
                  already have account
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <div className="logo-container">
                  <img src="./accros-logo.png" alt="accros logo" className="logo" />
                </div>
                <h1 className="overlay-heading">Hey! What's up..?</h1>
                <p className="overlay-description">Enter your details and let us get you onboard</p>
                <button className="ghost" id="signUp" onClick={() => setStep(1)}>
                  create an account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Auth;
