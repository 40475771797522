import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { BsPlus } from 'react-icons/bs';
import useClickOutside from '../../hooks/useClickOutside';
import socket from '../../modules/socket';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ServerType, setActiveServer, setServersList } from '../../redux/reducer';
import './style.scss';

const ServerList = () => {
  const [toggleVisibility, setToggleVisibility] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const activeServer = useAppSelector((state) => state.activeServer);
  const serversList = useAppSelector((state) => state.serversList);
  const createServer = () => {
    setToggleVisibility((prevValues) => !prevValues);
  };
  const changeActiveServer = (server: ServerType) => {
    localStorage.setItem('activeServer', JSON.stringify(server));
    dispatch(setActiveServer(server));
  };
  const storeServersList = (list: ServerType[] = []) => {
    sessionStorage.setItem('serversList', JSON.stringify(list));
  };
  const GetServerName = ({ hidden }: { hidden: boolean }) => {
    const [serverName, setServerName] = useState('');
    const getServer = useRef();
    useClickOutside(getServer, () => {
      if (hidden) setToggleVisibility(false);
    });

    const close: React.KeyboardEventHandler = (event) => {
      if (event.key === 'Escape') {
        setToggleVisibility((prevValues) => !prevValues);
      }
    };

    const handleFormSubmit: React.FormEventHandler = (event) => {
      event.preventDefault();
      socket.emit('newServer', { serverName }, (newServer: ServerType) => {
        dispatch(setServersList([...serversList, newServer]));
      });
      setServerName('');
      setToggleVisibility((prevValues) => !prevValues);
    };
    const handleInputChange: React.ChangeEventHandler = (event) => {
      setServerName((event.target as HTMLInputElement).value);
    };
    return (
      <>
        {!hidden ? null : (
          <div className="input-container" onKeyDown={close}>
            <form ref={getServer} className="input-form" onSubmit={handleFormSubmit} autoComplete="off">
              <h1 className="container-heading">Create new server</h1>
              <input
                type="text"
                name="serverName"
                id="server-name"
                placeholder="Server Name"
                onChange={handleInputChange}
                value={serverName}
                autoFocus
              />
              <button className="btn btn-primary" type="submit">
                Create
              </button>
            </form>
          </div>
        )}
      </>
    );
  };
  useEffect(() => {
    if (serversList.length === 0) {
      socket.emit('serversList');
    }
    socket.on('getServersList', (list) => {
      if (list.length < 1) {
        toast.error('You are not in any server, Create a server first.', { id: 'not in server error' });
        createServer();
      } else {
        if (activeServer || list.indexOf(activeServer) > -1) {
        } else changeActiveServer(list[0]);
        storeServersList(list);
        dispatch(setServersList(list));
      }
    });
    socket.on('updateServers', (server, sid) => {
      if (sid === user.sid) {
        changeActiveServer(server);
      }
      if (serversList.findIndex((userServer) => userServer.id === server.id) === -1) {
        dispatch(setServersList([...serversList, server]));
      }
    });
  }, [serversList]);
  return (
    <>
      <GetServerName hidden={toggleVisibility} />
      <div className="servers-container">
        {serversList.length > 0 &&
          serversList.map((server, index) => (
            <div
              key={index}
              className={activeServer && server.id === activeServer.id ? 'server-logo active' : 'server-logo'}
              onClick={() => changeActiveServer(server)}
              title={server.name}
            >
              <img
                className={activeServer && server.id === activeServer.id ? 'server-logo active' : 'server-logo'}
                referrerPolicy="no-referrer"
                src={`https://ui-avatars.com/api/?name=${server.name.replace(
                  ' ',
                  '+'
                )}&size=55&background=random&bold=true`}
                alt=""
              />
            </div>
          ))}
        {/* <div className={toggleVisibility ? 'server-logo active' : 'server-logo'}>
          <div className={toggleVisibility ? 'new active' : 'new'} onClick={createServer}>
            <BsPlus />
          </div>
        </div> */}
        <div className={'server-logo disabled'}>
          <div className="new disabled" onClick={false ? createServer : null}>
            <BsPlus />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServerList;
