import React, { useEffect, useRef, useState } from 'react';
import useClickOutside from '../../../hooks/useClickOutside';
import axios from '../../../modules/axios';
import { ChannelType, UserType } from '../../../redux/reducer';
import NewFile from '../NewFile';
import './style.scss';

interface Props {
  showChannelDetails: boolean;
  setShowChannelDetails: React.Dispatch<React.SetStateAction<boolean>>;
  channel: ChannelType | UserType;
}

const ChannelDetails = ({ showChannelDetails, setShowChannelDetails, channel }: Props) => {
  const [files, setFiles] = useState([]);
  const details = useRef();
  useClickOutside(details, () => {
    if (showChannelDetails) setShowChannelDetails(false);
  });
  const escClose: React.KeyboardEventHandler = (event) => {
    if (event.key === 'Escape') {
      setShowChannelDetails(false);
    }
  };
  const getFiles = async () => {
    if (showChannelDetails && channel) {
      const response = await axios.get(`/file-share/get-files/${channel.id}`);
      setFiles(
        (response as any).data.files &&
          (response as any).data.files.sort((a: { storedName: string }, b: { storedName: string }) => {
            if (a.storedName.split('.').pop() > b.storedName.split('.').pop()) {
              return 1;
            }
            if (a.storedName.split('.').pop() < b.storedName.split('.').pop()) {
              return -1;
            }
            return 0;
          })
      );
    }
  };
  useEffect(() => {
    getFiles();
  }, [showChannelDetails]);
  return (
    <>
      {showChannelDetails ? (
        <div className="channel-details-container" onKeyDown={escClose}>
          <div ref={details} className="channel-details">
            <div className="heading">Shared Files</div>
            <div className="shared-files">
              {files && files.length > 0 ? (
                files.map((file, index) => (
                  <NewFile
                    key={index}
                    name={file.storedName}
                    downloadable={true}
                    style={{ width: 'inherit', minWidth: '120px', borderRadius: '5px' }}
                  />
                ))
              ) : (
                <span className="no-files">No files shared yet</span>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ChannelDetails;
