import { configureStore } from '@reduxjs/toolkit';
import {
  activeChannelSlice,
  activeServerSlice,
  activeUserSlice,
  allUsersSlice,
  channelMessagesSlice,
  messagesSlice,
  serverChannelsSlice,
  serversListSlice,
  userSlice
} from '../reducer';

const store = configureStore({
  reducer: {
    messages: messagesSlice.reducer,
    user: userSlice.reducer,
    activeServer: activeServerSlice.reducer,
    activeChannel: activeChannelSlice.reducer,
    activeUser: activeUserSlice.reducer,
    serversList: serversListSlice.reducer,
    serverChannels: serverChannelsSlice.reducer,
    channelMessages: channelMessagesSlice.reducer,
    allUsers: allUsersSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
