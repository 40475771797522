import useEventListener from "../useEventListner";

const useClickOutside = (ref: React.MutableRefObject<any>, cb: Function) => {
  useEventListener('click', (event) => {
      if (ref.current == null || ref.current.contains(event.target)) return;
      cb(event);
    }, document);
}

export default useClickOutside;
