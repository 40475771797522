import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../../modules/axios';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { ServerType, setServersList } from '../../../redux/reducer';
import ResponseData from '../../../types/AxiosResponse';
import Avatar from '../../shared/Avatar';
import './styles.scss';

/*
  FLOW:
    user hit the url with serverId in params
      API request to get server details
    show server details
    check isAuth
      if not:
        redirect to auth page
      else:
        render this page
    onClick join button hit send put request to the API with details with:
      serverId
      userId
*/

const JoinServer = () => {
  const [server, setServer] = useState<ServerType>(null);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const { serverId } = useParams<{ serverId: string }>();
  let history = useHistory();
  const joinServer = async () => {
    if (!user) {
      toast.error('You need to login first.', { id: 'login first' });
      history.push('/');
      return;
    }
    try {
      const res = await axios.put<{ userId: string }, AxiosResponse<ResponseData>>(`/server/invite/${serverId}`, {
        userId: user.id,
      });
      if (!res.data.error) {
        toast.success(res.data.message, { id: 'join success' });
        dispatch(setServersList(undefined));
        history.push('/');
      } else {
        toast.error(res.data.message, { id: 'join error' });
      }
    } catch (err: any) {
      toast.error(err.message, { id: 'api error' });
      console.log(err);
    }
  };
  useEffect(() => {
    if (!server) {
      axios.get(`/server/get/${serverId}`).then((res: { data: { server: ServerType } }) => setServer(res.data.server));
    }
    return () => {};
  }, []);
  return (
    <>
      {!server ? (
        'loading'
      ) : (
        <div className="join-server-container">
          <div className="server">
            <h1 className="container-heading">Join New Server</h1>
            <div className="server-details">
              <img
                src={`https://ui-avatars.com/api/?name=${server.name.replace(
                  ' ',
                  '+'
                )}&size=120&background=random&bold=true`}
                alt=""
                className="server-logo"
              />
              <h2 className="server-name">{server.name}</h2>
              <div className="present-users">
                <h3 className="heading">Joined Users</h3>
                <div className="user">
                  <Avatar sex="woman" />
                  <span className="user-name">Linda</span>
                </div>
                <div className="user">
                  <Avatar sex="woman" />
                  <span className="user-name">Alina</span>
                </div>
                <div className="user">
                  <Avatar sex="man" />
                  <span className="user-name">Harry</span>
                </div>
                <div className="end"></div>
              </div>
              <button className="btn btn-primary" onClick={joinServer}>
                Join
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JoinServer;
