import 'emoji-mart/css/emoji-mart.css';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { FiRefreshCcw } from 'react-icons/fi';
import useOnScreen from '../../hooks/useOnScreen';
import socket from '../../modules/socket';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { MessageType, setChannelMessages, setChannelNewMessage } from '../../redux/reducer';
import { convertMutable, groupMessages } from '../../utils/groupMessages';
import scrollToBottom from '../../utils/scrollToBottom';
import FileShare from '../shared/FileShare';
import Message from '../shared/Message';
import RichInput from '../shared/RichInput';
import './style.scss';

const MessageWindow = () => {
  let [messages, setMessages] = useState([] as MessageType[]);
  const [send, setSend] = useState(false);
  const [loadMore, setLoadMore] = useState(true);
  const initialLoadMoreText = 'Load More';
  const [loadMoreText, setLoadMoreText] = useState(initialLoadMoreText);
  const [toggleVisibility, setToggleVisibility] = useState(false);
  const [typingStatus, setTypingStatus] = useState<{ [id: string]: string }>({ default: '' });
  const dispatch = useAppDispatch();
  const me = useAppSelector((state) => state.user);
  const activeChannel = useAppSelector((state) => state.activeChannel);
  const channelMessages = useAppSelector((state) => state.channelMessages);
  const activeUser = useAppSelector((state) => state.activeUser);
  const allUsers = useAppSelector((state) => state.allUsers);
  const messagesEndRef = useRef(null);
  let displayMessages = convertMutable(messages, me);
  groupMessages(displayMessages);
  const getFiles = () => {
    setToggleVisibility((prevValues) => !prevValues);
  };

  const groupBy = (array: any[], property: string | number) => {
    return array.reduce((prev, curr) => {
      const key = new Date(curr[property]).toLocaleString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });
      if (!prev[key]) {
        prev[key] = [];
      }
      prev[key].push(curr);
      return prev;
    }, {});
  };
  const groupedMessages = Object.entries(groupBy(displayMessages, 'time')).sort((a, b) => {
    return new Date(a[0]).valueOf() - new Date(b[0]).valueOf();
  });

  const sendMessage = (message: string) => {
    message = message
      .replace(/>\n+/g, '>')
      .replace(/\n+/g, '<br />')
      .replace(/\s+/g, ' ')
      .replace(/<p><\/p>/g, '<br />')
      .trim();
    const payload = {
      sender: me,
      channel: activeChannel.id,
      messages: [message],
      type: 'message',
    };
    socket.emit('sendMessage', payload, (response: any) => {
      const sentMessage = { ...payload, ...response };
      setMessages((prevMessages) => [...prevMessages, sentMessage]);
      dispatch(setChannelNewMessage({ channelId: activeChannel.id, message: sentMessage }));
    });
    scrollToBottom(messagesEndRef);
  };

  const sendPrivateMessage = (message: string) => {
    message = message.replace(/>\n+/g, '>').replace(/\n+/g, '<br />').replace(/\s+/g, ' ').trim();
    const payload = {
      sender: me,
      channel: activeUser.id,
      messages: [message],
      type: 'message',
    };
    socket.emit('sendPrivateMessage', payload, (response: any) => {
      const sentMessage = { ...payload, ...response };
      setMessages((prevMessages) => [...prevMessages, sentMessage]);
      dispatch(setChannelNewMessage({ channelId: activeUser.id, message: sentMessage }));
    });
    scrollToBottom(messagesEndRef);
  };
  const isVisible = useOnScreen(messagesEndRef);
  const loadMoreMessages = ({ activeChat, isPrivate }: { activeChat: string; isPrivate: boolean }) => {
    const skip = channelMessages[activeChat].length;
    if (skip) {
      setLoadMoreText('Loading');
      socket.emit('loadMoreMessages', { sender: me.id, activeChat, isPrivate, skip });
      socket.on('getMoreMessages', ({ channel: channelId, messages: oldMessages }) => {
        if (!oldMessages.length) {
          setLoadMore(false);
        } else {
          const message = [...oldMessages, ...channelMessages[channelId]];
          dispatch(setChannelMessages({ channelId, message }));
          if (activeUser?.id === activeChat || activeChannel?.id === activeChat) {
            setMessages(message);
          }
        }
        setLoadMoreText(initialLoadMoreText);
      });
    } else {
      setLoadMore(false);
    }
  };
  useEffect(() => {
    if (activeUser || activeChannel) {
      if (activeUser) {
        if (channelMessages && !Object.keys(channelMessages).includes(activeUser.id)) {
          socket.emit('fetchUserMessages', { sender: me.id, receiver: activeUser.id });
          socket.on('getMessages', (oldMessages, userId) => {
            if (oldMessages.length) {
              setLoadMore(true);
            }
            dispatch(
              setChannelMessages({
                channelId: userId,
                message: oldMessages,
              })
            );
            if (activeUser.id === userId) {
              setMessages(oldMessages);
            }
          });
        } else {
          setMessages(channelMessages[activeUser.id]);
        }
        if (channelMessages[activeUser.id]?.length) {
          setLoadMore(true);
        } else {
          setLoadMore(false);
        }
      } else {
        if (channelMessages && !Object.keys(channelMessages).includes(activeChannel.id)) {
          socket.emit('fetchGroupMessages', activeChannel.id);
          socket.on('getMessages', (oldMessages, channelId) => {
            if (oldMessages.length) {
              setLoadMore(true);
            }
            dispatch(setChannelMessages({ channelId: channelId, message: oldMessages }));
            if (activeChannel.id === channelId) {
              setMessages(oldMessages);
            }
          });
        } else {
          setMessages(channelMessages[activeChannel.id]);
        }
        if (channelMessages[activeChannel.id]?.length) {
          setLoadMore(true);
        } else {
          setLoadMore(false);
        }
      }
    }
    socket.on('receiveMessage', (payload: any) => {
      const isUser = allUsers.filter((user) => user.id === payload.channel);
      if (isUser.length > 0) {
        if (!channelMessages[payload.channel] || channelMessages[payload.channel].length < 1) {
          socket.emit('fetchUserMessages', { sender: me.id, receiver: payload.channel });
        }
      } else {
        if (!channelMessages[payload.channel] || channelMessages[payload.channel].length < 1) {
          socket.emit('fetchGroupMessages', payload.channel);
        }
      }
      dispatch(setChannelNewMessage({ channelId: payload.channel, message: payload }));
      scrollToBottom(messagesEndRef);
      if (activeChannel) {
        if (activeChannel.id === payload.channel) {
          setMessages(prevState => ([...prevState, payload]));
        }
      } else {
        if (activeUser.id === payload.channel) {
          setMessages(prevState => ([...prevState, payload]));
        }
      }
      if (isUser.length > 0) {
        socket.emit('delivered', { channelId: payload.channel, messageId: payload.sentMessageId, receiverId: me.id });
      }
      if (activeUser) {
        setSend(true);
      }
    });
    socket.on('userTyping', ({ room, msg, type, typingUserId }) => {
      if (type === 'group') {
        if (activeChannel && room === activeChannel.id) {
          setTypingStatus((prevValue) => ({ ...prevValue, [room]: msg }));
        }
      } else if (type === 'private') {
        if (activeUser && typingUserId === activeUser.id) {
          setTypingStatus((prevValue) => ({ ...prevValue, [typingUserId]: msg }));
        }
      }
    });
    socket.on('userStoppedTyping', ({ room, type, typingUserId }) => {
      if (type === 'group') {
        if (activeChannel && room === activeChannel.id) {
          setTypingStatus((prevValue) => ({ ...prevValue, [room]: '' }));
        }
      } else if (type === 'private') {
        if (activeUser && typingUserId === activeUser.id) {
          setTypingStatus((prevValue) => ({ ...prevValue, [typingUserId]: '' }));
        }
      }
    });
    scrollToBottom(messagesEndRef);
    return () => {
      socket.off('getMessages');
      socket.off('receiveMessage');
      socket.off('userTyping');
      socket.off('userStoppedTyping');
    };
  }, [activeChannel, activeUser]);
  useEffect(() => {
    socket.on('deliveryReport', ({ channelId, messageId }) => {
      const messageIndex = channelMessages[channelId].findIndex((message) => message.id === messageId);
      const updatedMessages = JSON.parse(JSON.stringify(channelMessages[channelId]));
      updatedMessages[messageIndex].status = 'delivered';
      dispatch(setChannelMessages({ channelId, message: updatedMessages }));
      if (activeUser && channelId === activeUser.id) {
        setMessages(updatedMessages);
      }
    });
    socket.on('readReport', ({ userId }) => {
      if (channelMessages[userId]) {
        let copiedMessages = JSON.parse(JSON.stringify(channelMessages[userId]));
        const updatedMessages = copiedMessages.map((message: MessageType) => {
          if (!message.status || message.status !== 'read') {
            message.status = 'read';
          }
          return message;
        });
        dispatch(setChannelMessages({ channelId: userId, message: updatedMessages }));
        if (activeUser && userId === activeUser.id) {
          setMessages(copiedMessages);
        }
      }
    });
    return () => {
      socket.off('deliveryReport');
      socket.off('readReport');
    };
  }, [channelMessages]);
  useEffect(() => {
    if (activeUser && send) {
      socket.emit('read', { senderId: activeUser.id, receiverId: me.id });
      setSend(false);
    }
  }, [send]);
  return (
    <>
      <FileShare
        hidden={toggleVisibility}
        setToggleVisibility={setToggleVisibility}
        setMessages={setMessages}
        messagesEndRef={messagesEndRef}
      />
      <div className="message-window">
        <div className="top-bar">
          <div className="channel-details">
            <div className="channel-name">
              {(activeChannel && activeChannel.name) || (activeUser && activeUser.name)}
            </div>
            <div className="channel-info">
              {(activeChannel && activeChannel.info) || (activeUser && activeUser.status)}
            </div>
          </div>
        </div>
        <div className="message-container">
          {loadMore && (
            <button
              className="btn btn-primary more"
              onClick={() =>
                loadMoreMessages(
                  activeUser
                    ? { activeChat: activeUser.id, isPrivate: true }
                    : { activeChat: activeChannel.id, isPrivate: false }
                )
              }
            >
              <FiRefreshCcw style={{ fontSize: '1rem', marginRight: '10px' }} />
              {loadMoreText}
            </button>
          )}
          {groupedMessages &&
            groupedMessages.map((groupedMessage, index) => {
              return (
                <>
                  <div key={index} className="date-container">
                    <div className="date">
                      {groupedMessage[0] ===
                      new Date(Date.now()).toLocaleString('en-US', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      })
                        ? 'Today'
                        : groupedMessage[0] ===
                          // HACK: 864e5 == 86400000 == 24*60*60*1000 i.e., 24 hours in milliseconds
                          new Date(Date.now() - 864e5).toLocaleString('en-US', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          })
                        ? 'Yesterday'
                        : groupedMessage[0]}
                    </div>
                  </div>
                  {(groupedMessage[1] as MessageType[]).map((message, subIndex) => (
                    <Message key={subIndex} payload={message} />
                  ))}
                </>
              );
            })}
          <div ref={messagesEndRef} />
        </div>
        <div className="bottom-bar">
          {!isVisible && (
            <button className="btn btn-primary scroll" onClick={() => scrollToBottom(messagesEndRef, 0)}>
              <AiOutlineArrowDown style={{ fontSize: '1rem', marginRight: '5px' }} />
              new messages
            </button>
          )}
          <div className="typing-status">
            {typingStatus[(activeChannel && activeChannel.id) || (activeUser && activeUser.id) || 'default']}
          </div>
          <RichInput
            sendMessage={activeUser ? sendPrivateMessage : sendMessage}
            getFiles={getFiles}
            placeholder={`Message ${(activeChannel && activeChannel.name) || (activeUser && activeUser.name)}`}
          />
        </div>
      </div>
    </>
  );
};

export default MessageWindow;
