import React from 'react';
import NewAvatar, { genConfig } from 'react-nice-avatar';
import { UserType } from '../../../redux/reducer';
import './style.scss';

interface Props {
  user?: UserType;
}

const Avatar = ({ user }: Props) => {
  try {
    const avatarConfig = user ? JSON.parse(user.avatar) : {};
    const newConfig = genConfig({ ...avatarConfig, hairColorRandom: true, isGradient: true });
    return <NewAvatar className="avatar" style={{ width: '10rem', height: '10rem' }} shape="square" {...newConfig} />;
  } catch (error) {
    return <img className="avatar" referrerPolicy="no-referrer" src={user.avatar} />;
  }
};

export default Avatar;
