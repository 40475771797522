import React from 'react';
import toast, { Toast as _Toast, ToastOptions } from 'react-hot-toast';
import './style.scss';

const infoOptions: ToastOptions = {
  icon: (
    <svg
      stroke="white"
      fill="#14efff"
      strokeWidth="0"
      viewBox="0 0 16 16"
      width="1.3rem"
      height="1.3rem"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minHeight: '1.3rem', minWidth: '1.3rem', animation: 'info 0.4s ease', animationDelay: '100ms' }}
    >
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
    </svg>
  ),
  iconTheme: {
    primary: 'white',
    secondary: '#05c9e3',
  },
  style: {
    color: 'white',
    background: '#0eb3d8',
  },
};

class CustomToast {
  toast: _Toast;

  info(jsxElement: JSX.Element | string, options?: ToastOptions) {
    toast(jsxElement, { ...infoOptions, ...options });
  }
}

const Toast = new CustomToast();

export default Toast;
