import download from 'downloadjs';
import React from 'react';
import {
  AiFillFile,
  AiFillFileExcel,
  AiFillFileImage,
  AiFillFileMarkdown,
  AiFillFilePdf,
  AiFillFilePpt,
  AiFillFileText,
  AiFillFileWord,
  AiFillFileZip,
} from 'react-icons/ai';
import { BsDownload, BsFileEarmarkCodeFill, BsFileEarmarkMusicFill, BsFileEarmarkPlayFill } from 'react-icons/bs';
import axios from '../../../modules/axios';
import './style.scss';

interface Props {
  file?: File;
  name?: string;
  downloadable?: boolean;
  style?: React.CSSProperties;
}

const FileIcon = ({ fileType }: { fileType: string }) => {
  const icon = {
    minWidth: '2.5rem',
    minHeight: '2.5rem',
  };
  switch (fileType) {
    case 'pdf':
      return <AiFillFilePdf style={icon} />;
    case 'xls':
    case 'xlsx':
    case 'csv':
    case 'number':
      return <AiFillFileExcel style={icon} />;
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'svg':
    case 'gif':
      return <AiFillFileImage style={icon} />;
    case 'txt':
      return <AiFillFileText style={icon} />;
    case 'md':
      return <AiFillFileMarkdown style={icon} />;
    case 'rar':
    case 'zip':
      return <AiFillFileZip style={icon} />;
    case 'docx':
    case 'page':
      return <AiFillFileWord style={icon} />;
    case 'ppt':
    case 'pptx':
    case 'key':
      return <AiFillFilePpt style={icon} />;
    case 'mp3':
    case 'wav':
    case 'ogg':
    case 'opus':
    case 'aac':
      return <BsFileEarmarkMusicFill style={icon} />;
    case 'mp4':
    case 'mov':
    case 'webm':
    case 'webp':
      return <BsFileEarmarkPlayFill style={icon} />;
    case 'html':
    case 'htm':
    case 'css':
    case 'scss':
    case 'sass':
    case 'ts':
    case 'tsx':
    case 'js':
    case 'jsx':
    case 'env':
    case 'json':
    case 'bson':
    case 'php':
      return <BsFileEarmarkCodeFill style={icon} />;
    default:
      return <AiFillFile style={icon} />;
  }
};

const NewFile = ({ file, name, downloadable = false, style = {} }: Props) => {
  const fileType = name ? name.split('.').pop().toLowerCase() : file.name.split('.').pop().toLowerCase();
  const downloadFile: React.MouseEventHandler = async () => {
    const res = await axios.get(`/file-share/download/${name}`, {
      responseType: 'blob',
    });
    download(new Blob([res.data]), name.slice(name.indexOf('-') + 1));
  };
  const eligiblePreview = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'svg',
    'mp4',
    'mov',
    'webm',
    'webp',
    'mp3',
    'aac',
    'oog',
    'opus',
    'wav',
  ].includes(fileType);
  const showPreview = () => {
    window.open(`${process.env.URL}/${name}`);
  };
  return (
    <>
      <div
        className="file"
        onClick={eligiblePreview ? showPreview : undefined}
        title={name ? name.slice(name.indexOf('-') + 1) : file.name}
        style={{ ...style, cursor: eligiblePreview ? 'pointer' : 'inherit' }}
      >
        {downloadable && (
          <div className="download" onClick={downloadFile}>
            <BsDownload />
          </div>
        )}
        <FileIcon fileType={fileType} />
        <span className="file-name">{name ? name.slice(name.indexOf('-') + 1) : file.name}</span>
      </div>
    </>
  );
};

export default NewFile;
