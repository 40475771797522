import React from 'react';
import './style.scss';

interface Props {
  value?: number;
}

const Badge = ({ value = 0 }: Props) => {
  return (
    <>
      {value !== 0 && (
        <div className="badge-container">
          <div className="badge">{value}</div>
        </div>
      )}
    </>
  );
};

export default Badge;
