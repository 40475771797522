import React from 'react';
import { Toaster } from 'react-hot-toast';
import { DefaultToastOptions } from 'react-hot-toast/dist/core/types';

const options: DefaultToastOptions = {
  success: {
    style: {
      background: '#388e3c',
      color: 'white',
    },
  },
  error: {
    style: {
      background: '#ce2c2c',
      color: 'white',
    },
  },
};

const SnackBar = () => {
  return <Toaster toastOptions={options} position="top-right" />;
};

export default SnackBar;
