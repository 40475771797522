import parse, { attributesToProps, domToReact, HTMLReactParserOptions } from 'html-react-parser';
import React, { MouseEventHandler } from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import { Element as DOMNodeElement } from '../../../../node_modules/domhandler/lib/node';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { ChannelType, MessageType, setActiveChannel, setActiveUser, UserType } from '../../../redux/reducer';
import Avatar from '../Avatar';
import NewFile from '../NewFile';
import './style.scss';

interface Props {
  payload: MessageType;
}

const Message = (props: Props) => {
  const { sender, time, messages, condition, type, status } = props.payload;
  const me = useAppSelector((state) => state.user);
  const allUsers = useAppSelector((state) => state.allUsers);
  const activeUser = useAppSelector((state) => state.activeUser);
  const activeServer = useAppSelector((state) => state.activeServer);
  const serverChannels = useAppSelector((state) => state.serverChannels[activeServer && activeServer.id]);
  const dispatch = useAppDispatch();
  const activateMentionUser: MouseEventHandler = (event) => {
    const mentionedUserId = (event.target as HTMLElement).dataset.id;
    const mentionedUser = allUsers.find((user: UserType) => user.id === mentionedUserId);
    dispatch(setActiveChannel(null));
    dispatch(setActiveUser(mentionedUser));
  };
  const activateMentionChannel: MouseEventHandler = (event) => {
    const mentionedChannelId = (event.target as HTMLElement).dataset.id;
    const mentionedChannel = serverChannels.find((channel: ChannelType) => channel.id === mentionedChannelId);
    dispatch(setActiveUser(null));
    dispatch(setActiveChannel(mentionedChannel));
  };
  const options: HTMLReactParserOptions = {
    replace: (DOMNode) => {
      const domNode = DOMNode as unknown as DOMNodeElement;
      if (domNode.attribs && domNode.attribs.class && domNode.attribs.class === 'mention') {
        if (domNode.attribs['data-id'] === me.id) {
          domNode.attribs.class += ' me';
        }
        const Props = attributesToProps(domNode.attribs);
        return (
          <span {...Props} onClick={activateMentionUser}>
            {domToReact(domNode.children)}
          </span>
        );
      }
      if (domNode.attribs && domNode.attribs.class && domNode.attribs.class === 'mention-channel') {
        const Props = attributesToProps(domNode.attribs);
        return (
          <span {...Props} onClick={activateMentionChannel}>
            {domToReact(domNode.children)}
          </span>
        );
      }
    },
  };
  return (
    <>
      {condition == 'receive' ? (
        <div className="message receive-message">
          <Avatar user={sender} />
          <div className="message-bubble-container">
            <div className="message-details">
              <span className="sender-name">{sender.name}</span>
              <span className="timestamp">
                {new Date(time).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' })}
              </span>
            </div>
            {messages.map((message: string, index: number) => (
              <div key={index} className="message-bubble">
                {type == 'message' ? (
                  parse(message, options)
                ) : (
                  <NewFile
                    name={message}
                    downloadable={true}
                    style={{ width: 'inherit', minWidth: '120px', borderRadius: '5px', maxWidth: '150px' }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="message send-message">
          <div className="message-bubble-container">
            <div className="message-details">
              <span className="timestamp">
                {new Date(time).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' })}
              </span>
              <span className="sender-name">You</span>
            </div>
            {messages.map((message: string, index: number) => (
              <div key={index} className="message-bubble">
                {type == 'message' ? (
                  parse(message, options)
                ) : (
                  <NewFile
                    name={message}
                    style={{ width: 'inherit', minWidth: '120px', borderRadius: '5px', maxWidth: '150px' }}
                  />
                )}
              </div>
            ))}
          </div>
          <Avatar user={me} />
          {activeUser && (
            <span className="read-receipts">
              {status === 'delivered' ? <BsCheckCircle /> : status === 'read' && <Avatar user={activeUser} />}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default Message;
