import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgressBar = ({ className, progress }: { className: string; progress: number }) => {
  return (
    <div className={className}>
      <CircularProgressbar
        value={progress}
        text={`${progress}%`}
        styles={buildStyles({
          trailColor: 'var(--background)',
          pathColor: 'var(--primary-color)',
          textColor: 'var(--primary-color)',
        })}
      />
    </div>
  );
};

export default CircularProgressBar;
