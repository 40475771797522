import { io } from 'socket.io-client';
import Toast from '../components/shared/InfoToast';

const socket = io({ autoConnect: false });
socket.on('reload', () => {
  localStorage.removeItem('activeChannel');
  localStorage.removeItem('activeServer');
  sessionStorage.clear();
  window.location.reload();
});
socket.on('announcement', (message: string) => {
  Toast.info(message, { duration: 6000 });
});
socket.onAny((event, ...args) => {
  console.log(event, args);
});

export default socket;
