import React, { useEffect, useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import socket from '../../modules/socket';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setActiveChannel, setActiveUser, setAllUsers, UserType } from '../../redux/reducer';
import Avatar from '../shared/Avatar';
import ChannelDetails from '../shared/ChannelDetails';
import './style.scss';

const Details = () => {
  const [showChannelDetails, setShowChannelDetails] = useState(false);
  const dispatch = useAppDispatch();
  const allUsers = useAppSelector((state) => state.allUsers);
  const me = useAppSelector((state) => state.user);
  const activeChannel = useAppSelector((state) => state.activeChannel);
  const activeUser = useAppSelector((state) => state.activeUser);
  const [userDetails, setUserDetails] = useState(activeUser);
  const [users, setUsers] = useState(allUsers);
  const onlineUsers = users && users.filter((user: UserType) => user.active && user.id !== me.id);
  const offlineUsers = users && users.filter((user: UserType) => !user.active && user.id !== me.id);
  const changeActiveUser = (User: UserType) => {
    dispatch(setActiveChannel(null));
    dispatch(setActiveUser(User));
  };
  const icon = {
    width: '1.5rem',
    height: '1.5rem',
    margin: '0 0.9rem',
  };
  useEffect(() => {
    socket.emit('usersActiveState');
    socket.on('getUsersActiveState', (updatedUsers: UserType[]) => {
      const newUsers = updatedUsers.filter((updatedUsersUser: UserType) =>
        allUsers.every((AllUsersUser: UserType) => AllUsersUser.id !== updatedUsersUser.id)
      );
      if (newUsers.length > 0) {
        dispatch(setAllUsers([...allUsers, ...newUsers]));
      }
      setUsers(updatedUsers);
    });
    if (activeUser) {
      socket.emit('userDetails', activeUser.id);
      socket.on('getUserDetails', (user) => {
        setUserDetails(user);
      });
    }
    return () => {
      socket.off('getUsersActiveState');
      socket.off('getUserDetails');
    };
  }, [activeUser]);
  return (
    <>
      <ChannelDetails
        showChannelDetails={showChannelDetails}
        setShowChannelDetails={setShowChannelDetails}
        channel={activeChannel || activeUser}
      />
      {activeUser ? (
        <div className="details-container">
          <div className="top-bar" onClick={() => setShowChannelDetails(true)}>
            User Details
            <BsArrowRight style={icon} />
          </div>
          <div className="user-details">
            <div className="main-container">
              <div className="user-avatar">
                <Avatar user={userDetails && userDetails} />
              </div>
              <div className="user-name-status">
                <h2 className="user-name">{userDetails && userDetails.name}</h2>
                <p className="user-status">{userDetails && userDetails.status}</p>
              </div>
            </div>
            <div className="personal-container">
              {/* <div className="online-status">
                {userDetails && userDetails.active ? (
                  <h5 className="status online">Online</h5>
                ) : (
                  <h4 className="status offline">Offline</h4>
                )}
              </div> */}
              <div className="sub-container email">
                <h4 className="heading">Email</h4>
                {userDetails && userDetails.email}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="details-container">
          <div className="top-bar" onClick={() => setShowChannelDetails(true)}>
            Channel Details
            <BsArrowRight style={icon} />
          </div>
          <div className="channel-details">
            <span className="main-heading">Users</span>
            <div className="detail-container">
              <span className="heading">
                <span className="title">You</span>
              </span>
              <div className="user me">
                <Avatar user={me} />
                <span className="name">{me.name}</span>
              </div>
            </div>
            <div className="detail-container">
              <span className="heading">
                <span className="title">Online</span>
              </span>
              {onlineUsers.length > 0 ? (
                onlineUsers.map((user: UserType, index: number) => (
                  <div key={index} className="user" onClick={() => changeActiveUser(user)}>
                    <Avatar user={user} />
                    <span className="name">{user.name}</span>
                  </div>
                ))
              ) : (
                <div className="no-one">No One is Online</div>
              )}
            </div>
            <div className="detail-container offline">
              <span className="heading">
                <span className="title">Offline</span>
              </span>
              {offlineUsers.length > 0 ? (
                offlineUsers.map((user: UserType, index: number) => (
                  <div key={index} className="user" onClick={() => changeActiveUser(user)}>
                    <Avatar user={user} />
                    <span className="name">{user.name}</span>
                  </div>
                ))
              ) : (
                <div className="no-one">No One is Offline</div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Details;
