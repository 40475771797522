import { AxiosResponse } from 'axios';
import React, { useRef, useState } from 'react';
import { AiFillFileAdd } from 'react-icons/ai';
import { FaShareSquare } from 'react-icons/fa';
import useClickOutside from '../../../hooks/useClickOutside';
import axios from '../../../modules/axios';
import socket from '../../../modules/socket';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { MessageType, setChannelNewMessage } from '../../../redux/reducer';
import scrollToBottom from '../../../utils/scrollToBottom';
import CircularProgressBar from '../CircularProgressBar';
import NewFile from '../NewFile';
import './style.scss';

interface Props {
  hidden: boolean;
  setToggleVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  setMessages: React.Dispatch<React.SetStateAction<MessageType[]>>;
  messagesEndRef: React.MutableRefObject<any>;
}

interface ResponseData {
  error: boolean;
  status: number;
  message: string;
  files: string[];
}

const FileShare = ({ hidden, setToggleVisibility, setMessages, messagesEndRef }: Props) => {
  const [files, setFiles] = useState([] as unknown as File[]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [progressBar, setProgressBar] = useState(true);
  const me = useAppSelector((state) => state.user);
  const activeChannel = useAppSelector((state) => state.activeChannel);
  const activeUser = useAppSelector((state) => state.activeUser);
  const dispatch = useAppDispatch();
  const fileShare = useRef();
  const icon = {
    width: '2.5rem',
    height: '2.5rem',
  };

  const stateReset = () => {
    setToggleVisibility(false);
    setDisabled(true);
    setProgressBar(true);
    setFiles([] as unknown as File[]);
    setUploadProgress(0);
  };

  useClickOutside(fileShare, () => {
    if (hidden) stateReset();
  });
  const fileAdd: React.ChangeEventHandler = async (event) => {
    const allFiles = (event.target as HTMLInputElement).files as unknown as File[];
    if (allFiles.length > 5) {
      (event.target as HTMLInputElement).value = null;
      alert('Maximum 5 files are allowed');
    } else {
      setFiles([...allFiles]);
      setDisabled(false);
    }
  };

  const sendFiles = async (filesArray: { [key: string]: File }) => {
    const formData = new FormData();
    setProgressBar(false);
    setDisabled(false);
    formData.append('sender', me.id);
    formData.append('location', activeChannel ? activeChannel.id : activeUser.id);
    formData.append('locationRef', activeChannel ? 'channels' : 'users');
    for (let key of Object.keys(filesArray)) {
      formData.append('files', filesArray[key]);
    }
    try {
      const res = await axios.post<typeof formData, AxiosResponse<ResponseData>>('/file-share/upload-files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          setUploadProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total));
        },
      });
      if (!res.data.error) {
        if (activeChannel) {
          const fileMessage = {
            sender: me,
            messages: [[...res.data.files.map((file: any) => file.storedName)].toString()],
            type: 'file',
            channel: activeChannel.id,
          };
          socket.emit('sendMessage', fileMessage, (response: any) => {
            const sentMessage = { ...fileMessage, ...response };
            setMessages((prevMessages) => [...prevMessages, sentMessage]);
            dispatch(setChannelNewMessage({ channelId: activeChannel.id, message: sentMessage }));
            scrollToBottom(messagesEndRef);
          });
        } else {
          const fileMessage = {
            sender: me,
            messages: [[...res.data.files.map((file: any) => file.storedName)].toString()],
            type: 'file',
            channel: activeUser.id,
          };
          socket.emit('sendPrivateMessage', fileMessage, (response: any) => {
            const sentMessage = { ...fileMessage, ...response };
            setMessages((prevMessages) => [...prevMessages, sentMessage]);
            dispatch(setChannelNewMessage({ channelId: activeUser.id, message: sentMessage }));
            scrollToBottom(messagesEndRef);
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const escClose: React.KeyboardEventHandler = (event) => {
    if (event.key === 'Escape') {
      stateReset();
    }
  };
  if (uploadProgress >= 100) {
    setTimeout(() => {
      stateReset();
    }, 1000);
  }
  return (
    <>
      {!hidden ? null : (
        <div className="selection-container">
          <div className="progress-bar-container" style={{ display: progressBar ? 'none' : 'flex' }}>
            <CircularProgressBar className="progress-bar" progress={uploadProgress} />
          </div>
          <div ref={fileShare} className="file-select-container" onKeyDown={escClose}>
            <div className="heading-container">
              <h1 className="heading">Select file to share:</h1>
            </div>
            <form encType="multipart/form-data" className="file-select">
              <input type="file" name="files" id="file-input" onChange={fileAdd} autoFocus multiple />
              <AiFillFileAdd className="icon" style={icon} />
            </form>
            <div className="selected-container">
              <div className="files">
                {files.map((file, index) => (
                  <NewFile key={index} file={file} />
                ))}
              </div>
            </div>
            <button
              className={disabled ? 'btn disabled' : 'btn btn-primary'}
              onClick={() => sendFiles(files)}
              disabled={disabled}
            >
              <FaShareSquare style={{ width: '1.2rem', height: '1.2rem', marginRight: '0.5rem' }} />
              Share Files
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FileShare;
