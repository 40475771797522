import React, { useEffect, useRef, useState } from 'react';
import { BsPlus, BsThreeDotsVertical } from 'react-icons/bs';
import useClickOutside from '../../hooks/useClickOutside';
import socket from '../../modules/socket';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  ChannelType,
  setActiveChannel,
  setActiveUser,
  setAllUsers,
  setServerChannels,
  setUser,
  UserType,
} from '../../redux/reducer';
import getAllUsers from '../../utils/getAllUsers';
import { ringNotification } from '../../utils/ring';
import Avatar from '../shared/Avatar';
import Badge from '../shared/Badge';
import './style.scss';

const ChannelList = () => {
  const [toggleVisibility, setToggleVisibility] = useState(false);
  const [channelsList, setChannelsList] = useState([] as ChannelType[]);
  const [showMenu, setShowMenu] = useState(false);
  const [notification, setNotification] = useState<{ [x: string]: number }>(
    JSON.parse(localStorage.getItem('usersNotification')) || {}
  );
  const me = useAppSelector((state) => state.user);
  const activeChannel = useAppSelector((state) => state.activeChannel);
  const serverChannels = useAppSelector((state) => state.serverChannels);
  const allUsers = useAppSelector((state) => state.allUsers);
  const activeUser = useAppSelector((state) => state.activeUser);
  const dispatch = useAppDispatch();
  const activeServer = useAppSelector((state) => state.activeServer);
  const menu = useRef();
  let notificationPermission: string;
  Notification.requestPermission().then((permission) => (notificationPermission = permission));
  useClickOutside(menu, () => {
    if (showMenu) setShowMenu(false);
  });
  const createChannel = () => {
    setToggleVisibility((prevValues) => !prevValues);
  };
  const changeActiveChannel = (channel: ChannelType) => {
    if (channel) {
      localStorage.setItem('activeChannel', JSON.stringify(channel));
      dispatch(setActiveChannel(channel));
      dispatch(setActiveUser(null));
      setNotification((prevValues) => {
        localStorage.setItem('usersNotification', JSON.stringify({ ...prevValues, [channel.id]: 0 }));
        return { ...prevValues, [channel.id]: 0 };
      });
      socket.emit('joinChannel', channel.id);
    }
  };
  const changeActiveUser = (User: UserType) => {
    if (User) {
      dispatch(setActiveChannel(null));
      localStorage.setItem('activeChannel', null);
      dispatch(setActiveUser(User));
      setNotification((prevValues) => {
        localStorage.setItem('usersNotification', JSON.stringify({ ...prevValues, [User.id]: 0 }));
        return { ...prevValues, [User.id]: 0 };
      });
      socket.emit('read', { senderId: User.id, receiverId: me.id });
    }
  };
  const logOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    setShowMenu(false);
    dispatch(setUser({ ...me, token: null }));
    socket.disconnect();
    window.location.reload();
  };
  const GetChannelName = ({ hidden }: { hidden: boolean }) => {
    const initialChannel = { name: '', info: '' };
    const [channel, setChannel] = useState(initialChannel);
    const newServer = useRef();
    useClickOutside(newServer, () => {
      if (hidden) setToggleVisibility(false);
    });

    const close: React.KeyboardEventHandler = (event) => {
      if (event.key === 'Escape') {
        setToggleVisibility((prevValues) => !prevValues);
      }
    };

    const channelSubmit: React.FormEventHandler = (event) => {
      event.preventDefault();
      socket.emit('newChannel', { channel, server: activeServer }, (newChannel: ChannelType) => {
        if (serverChannels[activeServer.id].findIndex((serverChannel) => serverChannel.id === newChannel.id) === -1) {
          dispatch(
            setServerChannels({
              serverId: activeServer.id,
              channels: [...serverChannels[activeServer.id], newChannel],
            })
          );
        }
      });
      setChannel(initialChannel);
      setToggleVisibility((prevValues) => !prevValues);
    };
    const channelChange: React.ChangeEventHandler = (event) => {
      const { name, value } = event.target as HTMLInputElement;
      setChannel({ ...channel, [name]: value });
    };
    return (
      <>
        {!hidden ? null : (
          <div className="input-container" onKeyDown={close}>
            <form ref={newServer} className="input-form" onSubmit={channelSubmit} autoComplete="off">
              <h1 className="container-heading">Create new channel</h1>
              <input
                type="text"
                name="name"
                id="channel-name"
                placeholder="Channel Name"
                onChange={channelChange}
                value={channel.name}
                autoFocus
              />
              <input
                type="text"
                name="info"
                id="channel-info"
                placeholder="Channel Description"
                onChange={channelChange}
                value={channel.info}
              />
              <button className="btn btn-primary" type="submit">
                Create
              </button>
            </form>
          </div>
        )}
      </>
    );
  };
  const addListeners = (notify: Notification) => {
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'visible') {
        notify.close();
      }
    });
    notify.onclick = function () {
      const { id, server, type } = JSON.parse(this.data);
      if (type === 'group') {
        changeActiveChannel(serverChannels[server].find((channel: ChannelType) => channel.id === id));
      } else {
        changeActiveUser(allUsers.find((user: UserType) => user.id === id));
      }
      window.focus();
    };
  };
  const updateUserIndex = (usersArray: UserType[], userId: UserType['id'], newIndex: number = 0) => {
    const copyUsersArray = [...usersArray];
    const oldIndex = copyUsersArray.findIndex((user) => user.id === userId);
    copyUsersArray.splice(newIndex, 0, copyUsersArray.splice(oldIndex, 1)[0]);
    return copyUsersArray;
  };
  useEffect(() => {
    if (serverChannels && activeServer) {
      if (!Object.keys(serverChannels).includes(activeServer.id)) {
        socket.emit('channelsList', { server: activeServer.id });
      } else {
        setChannelsList(serverChannels[activeServer.id]);
        if (!activeChannel) {
          changeActiveChannel(serverChannels[activeServer.id][0]);
          socket.emit('joinChannel', serverChannels[activeServer.id][0].id);
        } else {
          socket.emit('joinChannel', activeChannel.id);
        }
      }
    }
    if (allUsers.length === 0) {
      getAllUsers().then((users) => {
        dispatch(setAllUsers(users));
      });
    }

    socket.on('updateChannels', (payload) => {
      socket.emit('joinChannel', payload.channel.id);
      if (payload.sid === me.sid) {
        changeActiveChannel(payload.channel);
      }
      if (
        serverChannels[activeServer.id].findIndex((serverChannel) => serverChannel.id === payload.channel.id) === -1
      ) {
        dispatch(
          setServerChannels({
            serverId: payload.server.id,
            channels: [...serverChannels[activeServer.id], payload.channel],
          })
        );
      }
    });
    socket.on('getChannelsList', (channels, serverId) => {
      if (activeChannel && activeChannel.server === activeServer.id) {
        socket.emit('joinChannel', activeChannel.id);
      } else changeActiveChannel(channels[0]);
      dispatch(setServerChannels({ serverId, channels }));
      setChannelsList(channels);
    });
    socket.on('mentionNotification', (payload) => {
      ringNotification();
      if (notificationPermission === 'granted') {
        const notify = new Notification(payload.channel.name, {
          body: `${payload.sender} mentioned you`,
          icon: './accros-logo.png',
          data: JSON.stringify({
            id: payload.channel.id,
            server: payload.channel.server,
            type: 'group',
          }),
        });
        addListeners(notify);
      }
    });
    return () => {
      socket.off('updateChannels');
      socket.off('mentionNotification');
    };
  }, [activeServer, serverChannels]);
  useEffect(() => {
    socket.on(
      'notification',
      (payload: {
        receiver: { id: string; name: string; server: string };
        message: string;
        sender: string;
        type: 'group' | 'private';
      }) => {
        ringNotification();
        setNotification((prevValues) => {
          const exists = prevValues[payload.receiver.id];
          switch (payload.receiver.id) {
            case activeChannel && activeChannel.id:
              localStorage.setItem('usersNotification', JSON.stringify({ ...prevValues }));
              return { ...prevValues };
            case activeUser && activeUser.id:
              localStorage.setItem('usersNotification', JSON.stringify({ ...prevValues }));
              return { ...prevValues };
            default:
              const newNotification = exists
                ? { [payload.receiver.id]: prevValues[payload.receiver.id] + 1 }
                : { [payload.receiver.id]: 1 };
              localStorage.setItem('usersNotification', JSON.stringify({ ...prevValues, ...newNotification }));
              return { ...prevValues, ...newNotification };
          }
        });
        if (payload.type === 'private') {
          dispatch(setAllUsers(updateUserIndex(allUsers, payload.receiver.id)));
        }
        if (notificationPermission === 'granted') {
          const notify =
            payload.type === 'group'
              ? new Notification(`${payload.sender} in ${payload.receiver.name}`, {
                  body: payload.message.replace(/(<([^>]+)>)/gi, ''),
                  icon: './accros-logo.png',
                  data: JSON.stringify({
                    id: payload.receiver.id,
                    server: payload.receiver.server,
                    type: payload.type,
                  }),
                })
              : new Notification(payload.sender, {
                  body: payload.message.replace(/(<([^>]+)>)/gi, ''),
                  icon: './accros-logo.png',
                  data: JSON.stringify({ id: payload.receiver.id, type: payload.type }),
                });
          addListeners(notify);
        }
      }
    );
    return () => {
      socket.off('notification');
    };
  }, [allUsers, activeUser]);
  return (
    <>
      <GetChannelName hidden={toggleVisibility} />
      <div className="channel-container">
        <div className="user">
          <Avatar user={me} />
          <div className="user-details">
            <span className="user-name">{me.name}</span>
            <span className="user-status">{me.status}</span>
          </div>
          <div className="settings" onClick={() => setShowMenu((prevValue) => !prevValue)}>
            <BsThreeDotsVertical className="icon" />
          </div>
          {showMenu && (
            <div ref={menu} className="settings-menu">
              <div className="menu">
                <div className="menu-item" onClick={logOut}>
                  Logout
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="channel-sub-container">
          <details open>
            <summary>Channels</summary>
            {channelsList.length > 0 &&
              channelsList.map((channel, index) => (
                <div
                  key={index}
                  className={activeChannel && channel.id === activeChannel.id ? 'channel selected' : 'channel'}
                  onClick={() => changeActiveChannel(channel)}
                >
                  {channel.name}
                  {notification[channel.id] && channel.id !== (activeChannel && activeChannel.id) ? (
                    <Badge value={notification[channel.id]} />
                  ) : null}
                </div>
              ))}
            <div className="add-channel disabled" onClick={false ? createChannel : null}>
              <BsPlus /> Create Channel
            </div>
          </details>
          <details className="dm" open>
            <summary>Direct Messages</summary>
            {allUsers.length > 0 &&
              allUsers.map((User: UserType, index: number) => {
                if (User.id !== me.id) {
                  return (
                    <div
                      key={index}
                      className={activeUser && User.id === activeUser.id ? 'dm-user selected' : 'dm-user'}
                      onClick={() => changeActiveUser(User)}
                    >
                      <Avatar user={User} />
                      <span className="dm-user-name">{User.name}</span>
                      {notification[User.id] && User.id !== (activeUser && activeUser.id) ? (
                        <Badge value={notification[User.id]} />
                      ) : null}
                    </div>
                  );
                }
              })}
            {/* <div className="add-user">
              <BsPlus /> Add Friend
            </div> */}
          </details>
        </div>
      </div>
    </>
  );
};

export default ChannelList;
