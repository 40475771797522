import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface MessageType {
  id: string;
  sender: UserType;
  time?: string;
  messages: string[];
  condition: 'send' | 'receive';
  type: 'message' | 'file';
  status: string;
}

export interface UserType {
  id: string;
  name: string;
  email: string;
  status: string;
  active: boolean;
  avatar: string;
  googleId?: string;
  sid?: string;
  token?: string;
  channels?: string[];
}

export interface ServerType {
  id: string;
  name: string;
  owner: string;
}

export interface ChannelType {
  id: string;
  name: string;
  info: string;
  server: string;
}

export interface ServerChannelsType {
  [id: ServerType['id']]: ChannelType[];
}

export interface ChannelMessageType {
  [id: ChannelType['id']]: MessageType[];
}

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: null as MessageType[],
  reducers: {
    setMessages: (state, action: PayloadAction<MessageType[]>) => {
      if (state) {
        return [...state, ...action.payload];
      }
      return [...action.payload];
    },
  },
});

export const userSlice = createSlice({
  name: 'user',
  initialState: null as UserType,
  reducers: {
    setUser: (state, action: PayloadAction<UserType | null>) => {
      return action.payload;
    },
  },
});

export const activeServerSlice = createSlice({
  name: 'activeServer',
  initialState: (JSON.parse(localStorage.getItem('activeServer')) || null) as ServerType,
  reducers: {
    setActiveServer: (state, action: PayloadAction<ServerType | null>) => {
      return action.payload;
    },
  },
});

export const activeChannelSlice = createSlice({
  name: 'activeChannel',
  initialState: (JSON.parse(localStorage.getItem('activeChannel')) || null) as ChannelType,
  reducers: {
    setActiveChannel: (state, action: PayloadAction<ChannelType | null>) => {
      return action.payload;
    },
  },
});

export const activeUserSlice = createSlice({
  name: 'activeUser',
  initialState: null as UserType,
  reducers: {
    setActiveUser: (state, action: PayloadAction<UserType | null>) => {
      return action.payload;
    },
  },
});

export const serversListSlice = createSlice({
  name: 'serversList',
  initialState: (JSON.parse(sessionStorage.getItem('serversList')) || []) as ServerType[],
  reducers: {
    setServersList: (state, action: PayloadAction<ServerType[]>) => {
      sessionStorage.setItem('serversList', JSON.stringify(action.payload));
      return action.payload;
    },
  },
});

export const serverChannelsSlice = createSlice({
  name: 'serverChannels',
  initialState: (JSON.parse(sessionStorage.getItem('serverChannels')) || {}) as ServerChannelsType,
  reducers: {
    setServerChannels: (state, action: PayloadAction<{ serverId: ServerType['id']; channels: ChannelType[] }>) => {
      const newChannels = { [action.payload.serverId]: action.payload.channels };
      const allChannels = { ...newChannels };
      sessionStorage.setItem('serverChannels', JSON.stringify(allChannels));
      return allChannels;
    },
  },
});

export const channelMessagesSlice = createSlice({
  name: 'channelMessages',
  initialState: (JSON.parse(sessionStorage.getItem('channelMessages')) || {}) as ChannelMessageType,
  reducers: {
    setChannelMessages: (state, action: PayloadAction<{ channelId: ChannelType['id']; message: MessageType[] }>) => {
      const newMessages = { [action.payload.channelId]: action.payload.message };
      const allMessages = { ...state, ...newMessages };
      sessionStorage.setItem('channelMessages', JSON.stringify(allMessages));
      return allMessages;
    },
    setChannelNewMessage: (state, action: PayloadAction<{ channelId: ChannelType['id']; message: MessageType }>) => {
      if (!state[action.payload.channelId]) {
        state[action.payload.channelId] = [];
      }
      state[action.payload.channelId].push(action.payload.message);
      sessionStorage.setItem('channelMessages', JSON.stringify(state));
      return state;
    },
  },
});

export const allUsersSlice = createSlice({
  name: 'allUsers',
  initialState: (JSON.parse(sessionStorage.getItem('allUsers')) || []) as UserType[],
  reducers: {
    setAllUsers: (state, action: PayloadAction<UserType[]>) => {
      sessionStorage.setItem('allUsers', JSON.stringify(action.payload));
      return action.payload;
    },
  },
});

export const { setMessages } = messagesSlice.actions;
export const { setUser } = userSlice.actions;
export const { setActiveServer } = activeServerSlice.actions;
export const { setActiveChannel } = activeChannelSlice.actions;
export const { setActiveUser } = activeUserSlice.actions;
export const { setServersList } = serversListSlice.actions;
export const { setServerChannels } = serverChannelsSlice.actions;
export const { setChannelMessages, setChannelNewMessage } = channelMessagesSlice.actions;
export const { setAllUsers } = allUsersSlice.actions;
export const messages = (state: RootState) => state.messages;
export const user = (state: RootState) => state.user;
export const activeServer = (state: RootState) => state.activeServer;
export const activeChannel = (state: RootState) => state.activeChannel;
export const activeUser = (state: RootState) => state.activeUser;
export const serversList = (state: RootState) => state.serversList;
export const serverChannels = (state: RootState) => state.serverChannels;
export const channelMessages = (state: RootState) => state.channelMessages;
export const allUsers = (state: RootState) => state.allUsers;
export default messagesSlice.reducer;
